import { gql } from "@apollo/client";

export const GET_PRODUCT = gql`
  query getProduct ($filter: ProductFilter!) {
    getProduct(filter: $filter){
      id
      productType
      amount
      name
      image
      active
    }
  }
`;
