import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useLazyQuery, useQuery } from "@apollo/client";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";

import {
  PaymentFormType,
  PaymentLocation,
  PaymentProvider,
} from "@/autoGeneratedGlobalTypes";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@/components/common/button";
import CashbackDetailsCard from "@/components/common/cashbackDetailsCard";
import { Dropdown, DropdownSizeEnum, DropdownValue } from "@/components/common/dropdown";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import Loader from "@/components/common/loader";
import PaymentAmountPicker from "@/components/common/PaymentAmountPicker";
import PaymentCashback from "@/components/common/paymentCashback";
import { MIN_PAYMENT_AMOUNT, paymentTexts } from "@/components/constants";
import { FormTypeEnum } from "@/components/layout/modals/types";
import { setAuthParamToURL } from "@/components/layout/modals/utils";
import {
  CLOUD_PAYMENT_CARD_VALUE,
  CLOUD_PAYMENT_FOREIGN_CARD_VALUE,
  MAX_DIGITS_AMOUNT,
  PAYMENT_INITIAL_AMOUNT,
  ROBOKASSA_PAYMENT_CARD_VALUE,
  ROBOKASSA_SBP_PAYMENT_CARD_VALUE,
} from "@/constants";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { useFixPriceForServiceProducts } from "@/hooks/usePaymentFixProduct";
import { usePaymentForm } from "@/hooks/usePaymentForm";
import { pollingGetPaymentState_getPayment } from "@/hooks/usePaymentPolling/graphql/__generated__/pollingGetPaymentState";
import { usePayWithSavedCard } from "@/hooks/usePayWithSavedCard";
import { getDeviceType } from "@/utils/env";
import { currencyToString } from "@/utils/globalTypesUtils";
import { priceToString } from "@/utils/numberUtils";
import { cleanupFromTags } from "@/utils/stringUtils";

import {
  calculateCashback,
  calculateCashbackVariables,
} from "./graphql/__generated__/calculateCashback";
import { getPaymentPageData, getPaymentPageDataVariables } from "./graphql/__generated__/getPaymentPageData";
import { CALCULATE_CASHBACK } from "./graphql/CALCULATE_CASHBACK";
import { GET_PAYMENT_PAGE_DATA } from "./graphql/GET_PAYMENT_PAGE_DATA";
import { cardToJsx, getSuccessUrl, isAmountValid } from "./utils";

import "./styles.scss";

const PaymentForm = () => {
  const {
    userID,
    isFixedPaymentForProduct,
    unfinishedPaymentId,
    isUserLoggedIn,
  } = useContext<UserContextType>(UserContext);

  const [getPaymentDataQuery, { data, error, loading }] = useLazyQuery<getPaymentPageData, getPaymentPageDataVariables>(
    GET_PAYMENT_PAGE_DATA,
    {
      variables: {
        inputGetPaymentFormData: {
          formType: PaymentFormType.profile,
        },
      },
      fetchPolicy: "no-cache",
    },
  );

  useEffect(() => {
    if (isUserLoggedIn) {
      getPaymentDataQuery({ variables: {
        inputGetPaymentFormData: {
          formType: PaymentFormType.profile,
        },
      } });
    }
  }, [isUserLoggedIn, getPaymentDataQuery]);

  const [calculateCashbackQuery, { data: calculateCashbackData }] = useLazyQuery<
    calculateCashback,
    calculateCashbackVariables
  >(CALCULATE_CASHBACK, { fetchPolicy: "no-cache" });
  const [isAmountTouched, setIsAmountTouched] = useState<boolean>(false);
  const [selectedAmount, setSelectedAmount] = useState<number>(
    data?.getPaymentFormData.recommendedSumm || PAYMENT_INITIAL_AMOUNT,
  );
  const [cards, setCards] = useState<DropdownValue[]>([]);
  const [selectedCard, setSelectedCard] = useState<DropdownValue>(CLOUD_PAYMENT_CARD_VALUE);
  const [rememberCard, setRememberCard] = useState<boolean>(true);
  const [showCashBackDetailsCard, setShowCashBackDetailsCard] = useState<boolean>(false);
  const redirectPathAfterPayment = useLocation().href;// todo: maybe change to profile/result here and in appointment

  const isSelectedCloudPayemnt = selectedCard.value === CLOUD_PAYMENT_CARD_VALUE.value;
  const isSelectedCloudForeignPayemnt = selectedCard.value === CLOUD_PAYMENT_FOREIGN_CARD_VALUE.value;
  const isSelectedRobokassaPayemnt = selectedCard.value === ROBOKASSA_PAYMENT_CARD_VALUE.value;
  const isSelectedSBPPayemnt = selectedCard.value === ROBOKASSA_SBP_PAYMENT_CARD_VALUE.value;

  const { amountForProduct, productID } = useFixPriceForServiceProducts();

  useEffect(() => {
    if (isFixedPaymentForProduct && amountForProduct) {
      setSelectedAmount(amountForProduct);
    }
  }, [amountForProduct, isFixedPaymentForProduct]);

  useEffect(() => {
    if (selectedAmount && !isFixedPaymentForProduct) {
      calculateCashbackQuery({ variables: { input: { paymentAmount: selectedAmount } } });
    }
  }, [calculateCashbackQuery, selectedAmount, isFixedPaymentForProduct]);

  // Payment New Card
  const paymentSuccessCallback = useCallback(() => {
    const successUrl = getSuccessUrl();
    navigate(successUrl || `/profile/balance`, { state: { showPaymentAnimation: true } });
  }, []);

  // Payment New Card
  const paymentFailCallback = useCallback(() => {
    navigate(`/profile/payment`);
  }, []);

  const toggleShowCashBackDetailsCard = () => {
    const deviceType = getDeviceType();

    if (deviceType === "isMobile") {
      setShowCashBackDetailsCard((prevState) =>
        !prevState);
    } else {
      setAuthParamToURL(window?.location, FormTypeEnum.CashBackInformation);
    }
  };

  const hasCashBackButtons = useMemo(() =>
    data?.getPaymentFormData.buttons?.some((item) =>
      item.cashback?.amount), [data?.getPaymentFormData.buttons]);

  const { openPaymentForm, loadingPaymentNewCard } = usePaymentForm({
    onSuccess: paymentSuccessCallback,
    onFail: paymentFailCallback,
    productIdForFixPayment: productID,
  });

  // Payment saved Card
  const paymentWithSavedCardSuccessCallback = useCallback(
    (resultdata: pollingGetPaymentState_getPayment) => {
      const successUrl = getSuccessUrl();
      if (resultdata) {
        navigate(successUrl || "/profile/payment-result");
      }
    },
    [],
  );

  // Payment saved Card
  const paymentWithSavedCardFailCallback = useCallback(
    (resultData: pollingGetPaymentState_getPayment) => {
      if (resultData) {
        navigate("/profile/payment-result");
      }
    },
    [],
  );

  const { payWithSavedCard, loading: payWithSavedCardLoading } = usePayWithSavedCard({
    onSuccess: paymentWithSavedCardSuccessCallback,
    onFail: paymentWithSavedCardFailCallback,
    productIdForFixPayment: productID,
  });

  useEffect(() => {
    if (data && !error && !loading) {
      const joinRobokassaPaymentFlag = process.env.GATSBY_ADD_ROBOKASSA_PAYMENT === "true";
      const joinRobokassaSbpPaymentFlag = process.env.GATSBY_ADD_ROBOKASSA_SBP_PAYMENT === "true";

      const userCards: DropdownValue[] = data.getUserCards?.map((c) =>
        cardToJsx(c)) ?? [];

      userCards.push(CLOUD_PAYMENT_CARD_VALUE);

      if (joinRobokassaSbpPaymentFlag) {
        userCards.push(ROBOKASSA_SBP_PAYMENT_CARD_VALUE);
      }

      if (joinRobokassaPaymentFlag) {
        userCards.push(ROBOKASSA_PAYMENT_CARD_VALUE);
      }

      setCards(userCards);
      const defaultCard = data.getUserCards.find((card) =>
        card.isDefault);

      if (!isFixedPaymentForProduct) {
        setSelectedAmount(data?.getPaymentFormData.recommendedSumm);
      }

      if (defaultCard) {
        setSelectedCard(cardToJsx(defaultCard));
      } else {
        setSelectedCard(CLOUD_PAYMENT_CARD_VALUE);
      }
    }
  }, [data, error, isFixedPaymentForProduct, loading]);

  const handlePaymentClick = () => {
    switch (true) {
      case isSelectedCloudPayemnt || isSelectedCloudForeignPayemnt:
        openPaymentForm(
          selectedAmount,
          rememberCard,
          PaymentProvider.CloudPayments,
          "",
          isFixedPaymentForProduct ? PaymentLocation.fix_from_profile : PaymentLocation.profile,
          userID!,
        );
        break;
      case isSelectedRobokassaPayemnt:
        openPaymentForm(
          selectedAmount,
          false, // На первом этапе разработки карту не сохраняем!
          PaymentProvider.RobokassaPayments,
          redirectPathAfterPayment,
          isFixedPaymentForProduct ? PaymentLocation.fix_from_profile : PaymentLocation.profile,
          userID!,
        );
        break;
      case isSelectedSBPPayemnt:
        openPaymentForm(
          selectedAmount,
          false, // На первом этапе разработки карту не сохраняем!
          PaymentProvider.Other,
          redirectPathAfterPayment,
          isFixedPaymentForProduct ? PaymentLocation.fix_from_profile : PaymentLocation.profile,
          userID!,
        );
        break;
      default:
        payWithSavedCard(
          selectedAmount,
          Number(selectedCard.value),
          isFixedPaymentForProduct ? PaymentLocation.fix_from_profile : PaymentLocation.profile,
          userID!,
        );
    }
  };

  if (loading) {
    return <Loader />;
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = parseInt(e.target.value, 10);
    // user may enter zeros before the value, and we preserve them,
    // but we make sure the value is valid
    setSelectedAmount((oldValue) =>
      (numberValue.toString().length <= MAX_DIGITS_AMOUNT ? numberValue : oldValue));
  };

  const handleCardChange = (value: DropdownValue) => {
    setSelectedCard(value);
  };

  const handleBulletClick = (amount: number) => {
    setSelectedAmount(amount);
  };

  const getValueAmountInput = () =>
    (Number.isNaN(selectedAmount) ? "" : selectedAmount);

  const getSubtextForPaymentButton = (amount: number) => {
    if (amount) {
      return `на ${priceToString(amount)} ₽`;
    }
    return "";
  };

  const calculatedBonus = calculateCashbackData?.calculateCashback?.bonus;
  const calculatedProfit = calculateCashbackData?.calculateCashback?.profit;
  const showCheckboxForSaveCard = isSelectedCloudPayemnt || isSelectedRobokassaPayemnt || isSelectedCloudForeignPayemnt;

  return (
    <>
      <Helmet>
        <html className="html--full-height" lang="ru" />
        <body className="footer--short header--short" />
      </Helmet>
      <div className="profile-payment__container">
        <div className="profile-payment">
          <h2 className="profile-payment__header">Пополнение баланса</h2>
          {isFixedPaymentForProduct && <div className="profile-payment__subheader">для оплаты услуги на Lunaro</div>}
          {!isFixedPaymentForProduct && data?.getMyProfile && (
            <div className="profile-payment__card">
              <div>Ваш баланс</div>
              <h4 className="profile-payment__balance">
                <span
                  className="profile-payment__amount"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: cleanupFromTags(
                      priceToString(data.getMyProfile.balance.amount).replace(" ", "&nbsp;"),
                    ),
                  }}
                />
                &nbsp;
                <span className="profile-payment__currency">
                  {currencyToString(data.getMyProfile.balance.currency)}
                </span>
              </h4>
            </div>
          )}

          <form
            className="profile-payment__form"
            onSubmit={(e) =>
              e.preventDefault()}
          >
            <div className="profile-payment__field-group">
              <h4 className="profile-payment__label">
                <label htmlFor="amount">Сумма пополнения</label>
              </h4>
              <input
                id="amount"
                name="amount"
                type="number"
                pattern="\d*"
                inputMode="numeric"
                value={getValueAmountInput()}
                onChange={handleAmountChange}
                onBlur={() =>
                  setIsAmountTouched(true)}
                className={`profile-payment__input amount
                  ${isAmountTouched && !isAmountValid(selectedAmount) ? " error" : ""}
                  ${isFixedPaymentForProduct ? "profile-payment__fix-payment-input" : ""}
                `}
                aria-invalid={isAmountTouched && !isAmountValid(selectedAmount) ? "true" : "false"}
                disabled={isFixedPaymentForProduct}
              />
              {isAmountTouched && !isAmountValid(selectedAmount) && (
                <div className="auth__error" role="alert">
                  {`Минимальная сумма пополнения — ${MIN_PAYMENT_AMOUNT} ₽`}
                </div>
              )}
            </div>

            {!isFixedPaymentForProduct && (
              <PaymentAmountPicker
                handleBulletClick={handleBulletClick}
                dataFormButtons={data?.getPaymentFormData}
              />
            )}

            <div className="profile-payment__field-group">
              <h4
                className={`profile-payment__label ${hasCashBackButtons ? "profile-payment__label-for-cashback" : ""
                }`}
              >
                <label htmlFor="card">Способ пополнения</label>
              </h4>
              <Dropdown
                id="card"
                name="card"
                value={selectedCard}
                options={cards}
                onChange={handleCardChange}
                size={DropdownSizeEnum.Middle}
                className="profile-payment__input"
              />
            </div>

            <div className="profile-payment__payment-button-container">
              {showCashBackDetailsCard && (
                <CashbackDetailsCard onClick={toggleShowCashBackDetailsCard} />
              )}

              {!isFixedPaymentForProduct && calculatedBonus && !showCashBackDetailsCard && (
                <>
                  <PaymentCashback
                    bonusAmount={calculatedBonus.bonusAmount}
                    bonusFormula={calculatedBonus.bonusFormula}
                    profitAmount={calculatedProfit?.amount}
                    profitType={calculatedProfit?.type}
                    toggleShowCashBackDetailsCard={toggleShowCashBackDetailsCard}
                  />
                </>
              )}

              {!showCashBackDetailsCard && (
                <Button
                  text="Пополнить"
                  subtext={getSubtextForPaymentButton(selectedAmount)}
                  size={ButtonSizeEnum.Large}
                  color={ButtonColorEnum.Dark}
                  className="profile-payment__submit"
                  isLoading={payWithSavedCardLoading || loadingPaymentNewCard || !!unfinishedPaymentId}
                  disabled={!isAmountValid(selectedAmount)}
                  onClick={handlePaymentClick}

                />
              )}
            </div>

            {showCheckboxForSaveCard && (
              <div className="profile-payment__remember-block">
                {!isSelectedRobokassaPayemnt && (
                  <>
                    <div className="profile-payment__checkbox-group">
                      <input
                        type="checkbox"
                        checked={rememberCard}
                        onChange={() =>
                          setRememberCard((prevVal) =>
                            !prevVal)}
                      />
                      <span className="profile-payment__checkbox-label">
                        {paymentTexts.rememberText}
                      </span>
                    </div>
                    <div className="profile-payment__message">{paymentTexts.message}</div>
                  </>
                )}
                <div className="profile-payment__message">
                  <Icon type={IconTypeEnum.Shield} size={IconSizeEnum.Size28} />
                  {paymentTexts.protectionInfo}
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default PaymentForm;
